body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.container {
    flex-grow: 1;
    margin: 0 auto;
    position: relative;
    width: auto
}

.container.is-fluid {
    max-width: none !important;
    padding-left: 32px;
    padding-right: 32px;
    width: 100%
}

@media screen and (min-width:1024px) {
    .container {
        max-width: 960px
    }
}

@media screen and (max-width:1215px) {
    .container.is-widescreen:not(.is-max-desktop) {
        max-width: 1152px
    }
}

@media screen and (max-width:1407px) {
    .container.is-fullhd:not(.is-max-desktop):not(.is-max-widescreen) {
        max-width: 1344px
    }
}

@media screen and (min-width:1216px) {
    .container:not(.is-max-desktop) {
        max-width: 1152px
    }
}

@media screen and (min-width:1408px) {
    .container:not(.is-max-desktop):not(.is-max-widescreen) {
        max-width: 1344px
    }
}