* {
    box-sizing: border-box;
}
  
html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    /* overflow: hidden; */
}

body {
    /* overscroll-behavior-y: none; */
    scroll-behavior: auto;
    background: #f5f5f5;
    font-family: 'Inter var', sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: white;
}

.App {
    height: 100vh;
    width: 100%;
}

.App > .canvas {
    /* height: 100vh !important; */
}

.logo {
    position: fixed;
    top: 20px;
    left: 20px;
    height: 20px;
    width: 59px;
    background-image: url(../public/my-glasses.svg);
    background-size: contain;
    z-index: 1;
}

.page-2 {
    /* position: absolute;
    top: 100vh; */
    margin-top: 100vh;
    width: 100vw;
}

.page-3 {
    margin-top: 60vh;
    /* position: absolute;
    top: 200vh; */
    width: 100vw;
    background-color: #fff;
    color: #222;
}

.page-4 {
    margin-top: 40vh;
    width: 100%;
}

footer {
    text-align: center;
    color: #999;
    margin-top: 2rem;
}
